export default {
	order: [{
			name: '待付款',
			img: 'https://mall05.keduozan.com/static/user/daifukuan.png',
			jumpUrl: '/pages/index/order/order?tab=PendingPayment'
		},
		{
			name: '待收货',
			img: 'https://mall05.keduozan.com/static/user/daishouhuo.png',
			jumpUrl: '/pages/index/order/order?tab=PendingReceipt'
		},
		{
			name: '已完成',
			img: 'https://mall05.keduozan.com/static/user/daipingjia.png',
			jumpUrl: '/pages/index/order/order?tab=Completed'
		},
		{
			name: '退款/售后',
			img: 'https://mall05.keduozan.com/static/user/shouhou.png',
			jumpUrl: '/pages/index/order/order?tab=Refund'
		}
	],
	horse: [{
			"name": "我的订单",
			"img": "https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202404/24/416001-202404241416571064.png",
			"jumpUrl": "/pages/index/order/order?tab=all"
		},
		{
			"name": "个人资料",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/grzl.png",
			"jumpUrl": "/pages/index/booking/userInfo"
		},
		{
			"name": "在线预约",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/zxyy.png",
			"jumpUrl": "/pages/index/booking/horseH5"
		},
		{
			"name": "预约查询",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/yycx.png",
			"jumpUrl": "/pages/index/booking/horseHistory"
		},
		{
			"name": "在线充值",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/zxcz.png",
			"jumpUrl": "/pages/index/booking/rechange"
		},
		{
			"name": "课程包",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/xqlb.png",
			"jumpUrl": "/pages/index/booking/coursePacket"
		},
		{
			"name": "账号管理",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/qhzzh.png",
			"jumpUrl": "/pages/index/booking/accountManage"
		},
		{
			"name": "我的马匹",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/qhzzh.png",
			"jumpUrl": "/pages/index/booking/myHorse"
		},
		{
			"name": "消息中心",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/message.png",
			"jumpUrl": "/pages/index/booking/messageLog"
		},
		{
			"name": "优惠券",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/xqlb.png",
			"jumpUrl": "/pages/index/booking/coupon"
		},
		{
			"name": "切换账号",
			"img": "http://mall16.5izg.bj.cn/horse/m/t02/img/center/qhzzh.png",
			"jumpUrl": "switchAccounts"
		},
		{
			"name": "退出登录",
			"img": "https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202404/25/724555-202404251111059655.png",
			"jumpUrl": "userQuit"
		}
	],
	myFn: [{
			name: '优惠券',
			img: '/static/user/youhuiquan.png',
			jumpUrl: '/pages/user/coupon/coupon'
		},
		{
			name: '我的红包',
			img: '/static/user/hongbao.png',
			jumpUrl: '/pages/user/redPacket/redPacket'
		},
		{
			name: '我的收藏',
			img: '/static/user/shoucang.png',
			jumpUrl: '/pages/user/like/like'
		},
		{
			name: '浏览记录',
			img: '/static/user/liulanjilu.png',
			jumpUrl: '/pages/user/history/history'
		}
	],
	tools: [{
			name: '我的钱包',
			img: '/static/user/qianbao.png'
		},
		{
			name: '官方客服',
			img: '/static/user/kefu.png'
		},
		{
			name: '用户反馈',
			img: '/static/user/yonghufankui.png'
		},
		{
			name: '资质证照',
			img: '/static/user/zizhizhengzhao.png'
		},
		{
			name: '协议规则',
			img: '/static/user/xieyiguize.png'
		},
		{
			name: '线下门店',
			img: '/static/user/xianxiamendian.png'
		},
		{
			name: '更新日志',
			img: '/static/user/update.png',
			url: '/pages/user/update/update'
		}
	]

}