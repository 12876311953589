<!--
 * @Description: 个人中心
-->
<template>

	<div class="login" :style="[getStyle()]">
		<!-- logo区域 -->
		<div class="center-top" :style="[topBgStyle()]">
			<div class="headAll">
				<div class="top-head">
					<div class="head-img">
						<img :src="project.setting.me.headImg" style="width: 60px;height: 60px;" />
					</div>
				</div>
				<div class="top-name">注册/登录</div>
			</div>
			<div class="gird">
				<div class="girdLine">
					<span class="num">0</span>
					<span class="texts">余额</span>
				</div>
				<div class="girdLine">
					<span class="num">0</span>
					<span class="texts">次数</span>
				</div>
				<div class="girdLine">
					<span class="num">0</span>
					<span class="texts">积分</span>
				</div>
			</div>
		</div>


		<!-- 我的订单模块 -->
		<div class="list-box" v-if="project.setting.me.showOrderMenu=='1'">
			<div class="head flex col-center">
				<div class="title">我的订单</div>
				<div class="more flex">
					<text>全部订单</text>
					<u-icon name="right" customPrefix="custom-icon"></u-icon>>
				</div>
			</div>
			<div class="item">
				<div class="flex-column col-center" v-for="(item, index) in menu.order" :key="index">
					<img :src="item.img"></img>
					<div>{{ item.name }}</div>
				</div>
			</div>
		</div>



		<!-- 马术模块 我的功能 -->
		<div class="list-box"  v-if="project.setting.me.showBookingMenu=='1'" >
			<div class="head flex col-center">
				<div class="title">我的功能</div>
			</div>
			<div class="item">
				<div class="flex-column col-center pb20" v-for="(item, index) in menu.horse" :key="index">
					<img :src="item.img"></img>
					<div class="pt10">
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>




	</div>



</template>

<script>
	import {
		mapGetters
	} from "vuex";

	import menu from "@/config/myMenu";

	export default {
		name: "MeBar",

		data() {
			return {
				headImg: '',
				menu,
			};
		},

		computed: {
			...mapGetters(["project"]),
		},
		mounted() {
			console.log('进入个人中心设置页--------->', this.project);
			this.headImg = this.project.setting.me.headImg;
			//如果后台有配置的menu,那么以后台为准,如果没有,走本地的配置
			if (this.project.setting.me.menuJson != "") {
				// console.log(this.project.setting.me.menuJson);
				// console.log(this.menu.horse);
				this.menu.horse = JSON.parse(this.project.setting.me.menuJson);
			}


		},
		methods: {
			//背景图
			getStyle() {
				//页面的背景图
				return {
					'min-height': '812px',
				}
			},
			// 账号密码登录区域样式
			topBgStyle() {
				return {
					'background-image': 'url(' + this.project.setting.me.bgImg + ')',
					height: this.project.setting.me.topBgHeight, //默认250
				}
			},

		},

	};
</script>


<style lang='scss'>
	.center-top {
		width: 100%;
		overflow: hidden;
		background: url('http://xcx01.keduozan.com/upload/202205/20/202205201041123744.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
		min-height: 250px;
		//background-size: 100%;
	}

	.headAll {
		display: block;
	}

	.top-head {
		display: block;
	}

	.head-img {
		//margin-right: .8em;
		width: 60px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		margin: 100px auto 35px auto;
		line-height: 100px;
		border-radius: 30px;
	}

	.head-img img {
		width: 100%;
		max-height: 100%;
		vertical-align: top;
		border-radius: 30px;
	}

	.top-name {
		display: block;
		text-align: center;
		color: #fff;
		font-weight: 550;
		font-size: 22px;
	}



	.und {
		position: relative;
		overflow: hidden;
	}

	.und .undList {
		position: relative;
		float: left;
		padding: 20px 10px;
		width: 25%;
		box-sizing: border-box;
		text-align: center;
	}

	.undTopImg {
		width: 45px;
		height: 45px;
		margin: 0 auto;
	}

	.undName {
		margin-top: 10px;
	}


	.login-btn {
		width: 120px;
		border-radius: 30px !important;
		background: rgba(188, 175, 166, 1) !important;
		color: white !important;
		margin: auto;
	}



	.gird {
		position: relative;
		overflow: hidden;
		margin-top: 10px;
	}

	.gird .girdLine {
		position: relative;
		float: left;
		padding: 20px 10px;
		width: 33.33333333%;
		box-sizing: border-box;
		text-align: center;
		color: #fff;
	}

	.gird .girdLine:after,
	.gird .girdLine:before {
		content: " ";
		position: absolute;
		right: 0;
		bottom: 0;
		color: #d9d9d9;
	}

	.gird .girdLine:before {
		top: 0;
		width: 1px;
		border-right: 1px solid #d9d9d9;
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
		-webkit-transform: scaleX(.5);
		transform: scaleX(.5);
	}

	.gird .girdLine .num {
		display: block;
		font-size: 18px;
		font-weight: 550;
	}

	.gird .girdLine .texts {
		font-size: 14px;
	}

	.midTitle {
		display: block;
		padding: 10px;
	}

	.midTitle .midLeft {
		display: inline-block;
		vertical-align: middle;
		width: 50%;
		font-size: 16px;
	}

	.midRight {
		display: inline-block;
		vertical-align: middle;
		width: 50%;
		text-align: right
	}

	.midRightimg {
		display: inline-block;
		vertical-align: middle;
	}

	.midRight image {
		width: 15px;
		height: 15px;
		margin-right: 5px;
	}





	page {
		background-color: #f6f6f6;
	}

	.top-bg {
		display: inline-block;
		width: 100%;
		height: 235px;
		/* background: url("https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202404/06/682206-202404061439025946.png") no-repeat center / 100% 100%; */
	}

	.user {
		display: flex;
		align-items: center;
		margin-top: 80px;
		margin-bottom: 20px;

		.avatar {
			width: 58px;
			height: 58px;
			border-radius: 50%;
			margin-left: 15px;
		}

		.nickname {
			position: relative;
			font-size: 17px;
			margin-left: 10px;
			font-weight: bold;
			color: #333;

			.login-btn {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
			}
		}
	}

	.list-box {
		width: 100%;
		margin: 20px auto 13px;
		padding-bottom: 30px;
		border-radius: 9px;
		background-color: #ffffff;
		overflow: hidden;

		// box-sizing: border-box;
		.head {
			height: 48px;
			padding: 0 16px;
			border-bottom: 0.5px solid #f5f5f5;

			.title {
				font-size: 16px;
				color: #333;
				font-weight: bold;
			}

			.more {
				margin-left: auto;
				color: #999;
				font-size: 12px;
			}
		}

		.item {
			display: flex;
			flex-wrap: wrap;
			padding: 0 10px;
			text-align: center;
			font-size: 14px;
			color: #555;
			box-sizing: border-box;

			&>div {
				width: 25%;
				margin-top: 16px;
			}

			img {
				width: 40px;
				height: 40px;
			}

			.tools-img {
				width: 28px;
				height: 28px;
			}
		}
	}
</style>